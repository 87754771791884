import GenericHead from 'components/GenericHead';
import ListenInApp from 'components/ListenInApp';
import PageBody, { ViewName } from 'views/PageBody';
import useTranslate from 'contexts/TranslateContext/useTranslate';
import { Article } from 'state/News/types';
import { createWidgetQuery } from 'state/Routing/helpers';
import { getArtistUrl, getScaledImageUrlById } from 'utils/url';
import { Helmet } from 'react-helmet';
import { LatestNews, NewsChild } from 'components/LatestNews';
import { resolve } from 'url';
import { STATION_TYPE } from 'constants/stationTypes';

export type Props = {
  articles: Array<Article>;
  contentLink: string;
  id: number;
  name: string;
  path: string;
  siteUrl: string;
};

function ArtistNews({ articles, contentLink, name, id, siteUrl, path }: Props) {
  const translate = useTranslate();

  const url = resolve(siteUrl, path);
  const keywords = translate(
    '{name}, News, Music, Headlines, iHeartRadio, iHeart, Radio',
    {
      name,
    },
  );
  const description = translate('Latest {name} news from iHeart', { name });

  const artistUrl = getArtistUrl(id, name) as string;

  const social = {
    seedId: id,
    seedType: STATION_TYPE.ARTIST,
    supportsConnect: false,
    url: artistUrl,
  };

  return (
    <>
      <ListenInApp seedId={id} seedType="artist" />
      <GenericHead
        deeplink={`goto/artist/${id}`}
        description={description}
        image={getScaledImageUrlById(id, STATION_TYPE.ARTIST)}
        legacyDeeplink={`play/artist/${id}`}
        ogType="music.radio_station"
        title={translate('Read the latest News on {name} Online', { name })}
        twitterCard="player"
      />
      <Helmet
        meta={[
          {
            content: keywords,
            name: 'keywords',
          },
          {
            content: '300',
            key: 'twitter:player:width',
            name: 'twitter:player:width',
          },
          {
            content: '400',
            key: 'twitter:player:height',
            name: 'twitter:player:height',
          },
          {
            content: `${url}?${createWidgetQuery('tw')}`,
            key: 'twitter:player',
            name: 'twitter:player',
          },
          {
            content: `${url}?${createWidgetQuery('fb')}`,
            key: 'og:video:url',
            property: 'og:video:url',
          },
          {
            content: `${url}?${createWidgetQuery('fb')}`,
            key: 'og:video:secure_url',
            property: 'og:video:secure_url',
          },
          {
            content: 'text/html',
            key: 'og:video:type',
            property: 'og:video:type',
          },
          { content: '300', key: 'og:video:width', property: 'og:video:width' },
          {
            content: '400',
            key: 'og:video:height',
            property: 'og:video:height',
          },
          { content: 'audio/vnd.facebook.bridge', property: 'og:audio:type' },
          { content: url, property: 'og:audio' },
        ]}
      />
      <PageBody
        backLink={artistUrl}
        dataTest={ViewName.ArtistNews}
        richResultsMarkup={{
          name,
          id,
          description,
          keywords,
          url,
          seedType: STATION_TYPE.ARTIST,
        }}
        social={social}
        title={name}
      >
        <LatestNews heading={`${name} News`}>
          {articles.map(article => (
            <NewsChild
              href={`${contentLink}${article.slug}/`}
              img={`${article['@img']}?ops=${encodeURIComponent(
                'fit(770,435)',
              )}`}
              isSponsored={article.is_sponsored}
              key={article.title}
              title={article.title}
            />
          ))}
        </LatestNews>
      </PageBody>
    </>
  );
}

export default ArtistNews;
