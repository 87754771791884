import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import styled from '@emotion/styled';

const SectionTitle = styled('h1')(({ theme }) => ({
  display: 'inline-block',
  fontSize: '1.6rem',
  fontWeight: 'bold',
  lineHeight: '2.97rem',
  paddingBottom: '1rem',
  ...theme.mixins.ellipsis,
  verticalAlign: 'middle',
  zoom: 1,

  [mediaQueryBuilder(theme.mediaQueries.min.width['768'])]: {
    fontSize: '2rem',
  },
}));

export default SectionTitle;
