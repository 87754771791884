import GenericHead from 'components/GenericHead';
import ListenInApp from 'components/ListenInApp';
import PageBody, { ViewName } from 'views/PageBody';
import SectionTitle from 'components/SectionTitle';
import useTranslate from 'contexts/TranslateContext/useTranslate';
import { createWidgetQuery } from 'state/Routing/helpers';
import { getPodcastUrl } from 'state/Podcast/helpers';
import { getScaledImageUrlById } from 'utils/url';
import { Helmet } from 'react-helmet';
import { LatestNews, NewsChild } from 'components/LatestNews';
import { PodcastArticle } from 'state/Podcast/types';
import { resolve } from 'url';
import { STATION_TYPE } from 'constants/stationTypes';

type Props = {
  articles: Array<PodcastArticle>;
  contentLink: string;
  id: number;
  name: string;
  path: string;
  siteUrl: string;
  slug: string;
};

export default function PodcastNews({
  articles,
  contentLink,
  name,
  id,
  slug,
  siteUrl,
  path,
}: Props) {
  const translate = useTranslate();

  const url = resolve(siteUrl, path);
  const keywords = translate(
    '{name}, Blog, News, Music, Headlines, iHeartRadio, iHeart, Radio',
    {
      name,
    },
  );
  const title = translate('{name} Blog, News & Videos', { name });
  const description = translate(
    'Go behind the scenes, get exclusive stories and learn more about the show!',
  );
  const podcastUrl = getPodcastUrl(id, slug);
  const social = {
    seedId: id,
    seedType: STATION_TYPE.PODCAST,
    supportsConnect: false,
    url: podcastUrl,
  };
  return (
    <>
      <ListenInApp seedId={id} seedType={STATION_TYPE.PODCAST} />
      <GenericHead
        description={description}
        image={getScaledImageUrlById(id, 'show')}
        ogType="website"
        title={title}
        twitterCard="player"
      />
      <Helmet
        meta={[
          {
            content: keywords,
            name: 'keywords',
          },
          {
            content: '300',
            key: 'twitter:player:width',
            name: 'twitter:player:width',
          },
          {
            content: '400',
            key: 'twitter:player:height',
            name: 'twitter:player:height',
          },
          {
            content: `${url}?${createWidgetQuery('tw')}`,
            key: 'twitter:player',
            name: 'twitter:player',
          },
          {
            content: `${url}?${createWidgetQuery('fb')}`,
            key: 'og:video:url',
            property: 'og:video:url',
          },
          {
            content: `${url}?${createWidgetQuery('fb')}`,
            key: 'og:video:secure_url',
            property: 'og:video:secure_url',
          },
          {
            content: 'text/html',
            key: 'og:video:type',
            property: 'og:video:type',
          },
          { content: '300', key: 'og:video:width', property: 'og:video:width' },
          {
            content: '400',
            key: 'og:video:height',
            property: 'og:video:height',
          },
          { content: 'audio/vnd.facebook.bridge', property: 'og:audio:type' },
          { content: url, property: 'og:audio' },
        ]}
      />
      <PageBody
        backLink={podcastUrl}
        dataTest={ViewName.PodcastNews}
        social={social}
        title={name}
      >
        <LatestNews heading={<SectionTitle>{name} News</SectionTitle>}>
          {articles.map(article => (
            <NewsChild
              href={`${contentLink}${article.slug}/`}
              img={`${article.summary.image}?ops=${encodeURIComponent(
                'fit(770,435)',
              )}`}
              isSponsored={article.payload.is_sponsored}
              key={article.slug}
              title={article.summary.title}
            />
          ))}
        </LatestNews>
      </PageBody>
    </>
  );
}
